const handlerName = 'clickedOutsideHandler'

export default {
  mounted(el, binding) {
    const callback = binding.value

    // "ready" variable prevents the callback to be executed too soon
    // e.g.:
    // - "click" event is bound to a button which show a div
    // - "clicked-outside" directive is bound to this div, with a callback that hide that div
    // - without the "ready" variable check, click on the button will show,
    // then immediately hide the div because the clicked outside directive's callback will be trigerred too
    // - with the "ready" variable check, it will only show the div, without triggerring the clicked outside directive's callback
    let ready = false
    setTimeout(() => {
      ready = true
    }, 0)

    el[handlerName] = (event) => {
      // Execute callback if click event is outside of element
      if (
        ready
        && typeof callback === 'function' // Callback must be a function
        && !(el === event.target || el.contains(event.target)) // Click must not be on event's target or its children, but on an outside element
      ) {
        callback()
      }
    }

    window.addEventListener('click', el[handlerName])
  },
  beforeUnmount(el) {
    window.removeEventListener('click', el[handlerName])
  },
}
