export default ((router, store, options = {}) => {
  router.beforeEach((to, _, next) => {
    // Catch flash messages and display them
    if (to.query.danger) {
      store.commit('flashes/ADD_FLASH', { message: to.query.danger, type: 'danger' })
    } else if (to.query.success) {
      store.commit('flashes/ADD_FLASH', { message: to.query.success, type: 'success' })
    }

    const isAuthPage = to.path.startsWith('/auth')

    // Handle redirection on (un)authenticated users
    if (
      !isAuthPage
        && !store.state.auth.authenticated
    ) {
      // Redirect unauthenticated user to auth page
      next({ name: options.auth_route_name ?? 'login' })
      // Save initial path to redirect user after login
      store.commit('router/SET_AFTER_LOGIN_REDIRECT_ROUTE', to)
    } else if (
      isAuthPage
      && store.state.auth.authenticated
    ) {
      // Redirect authenticated user to home page
      next({ name: options.home_route_name ?? 'root' })
    } else {
      next()
    }
  })
})
