const state = {
  excursion_form: {},
  point_of_interest_form: {},
  point_of_interest_show: {},
  user_show: {},
  geoloc_failed: {},
}

const mutations = {
  SHOW(state, modalName) {
    state[modalName].display = true
  },
  RESET(state, modalName) {
    state[modalName] = {}
  },
  SET_PROPERTIES(state, payload) {
    // Separate name and other properties
    const { name, ...properties } = payload

    state[name] = {
      ...state[name],
      ...properties,
    }
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
