<template>
  <div
    ref="notifRef"
    :class="`swipe-${swipeDirection}`"
    :data-swipable="true"
    @touchstart="handleTouchStart"
    @touchend="handleTouchEnd"
  >
    <router-link
      v-slot="{ navigate }"
      :to="sourceRoute"
      custom
    >
      <app-card-item
        :class="`p-3 text-center break-words border-l-8 border-theme-500 ${clickable ? 'cursor-pointer' : null }`"
        @click="() => handleClick(navigate)"
      >
        <p class="text-sm font-bold">
          {{ title }}
        </p>
      </app-card-item>
    </router-link>
  </div>
</template>

<script setup>
import { computed, ref, nextTick } from 'vue'

import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import useNotifications from '@shared/hooks/notifications'

const props = defineProps({
  // Notification's id
  id: {
    type: String,
    required: true,
  },
  // Type and id of the resource
  // related to the notification
  source: {
    type: Object,
    default: () => ({}),
  },
  // Primary text displayed
  title: {
    type: String,
    default: null,
  },
  // Notification type
  type: {
    type: String,
    default: null,
  },
})

const emits = defineEmits([
  'closed',
])

const {
  sourceRoute,
  clickable,
} = useNotifications(props)

function handleClick(callback) {
  callback()
  emits('closed')
}

// ---------- TOUCH TO SWIPE NOTIF ----------

const startX = ref(null) // x coord at touch start
const endX = ref(null) // x coord at touch end
const notifRef = ref(null) // ref to element component
const swipeDirection = ref('right')

// Swipe distance is 2/5 of the notif's width
const swipeDistance = computed(() => (
  (
    notifRef.value?.clientWidth ?? 0
    * (2 / 5)
  )
))

function handleTouchStart(e) {
  // only if 1 finger touched the device
  if (e.changedTouches?.length === 1) {
    // save the touch start position
    startX.value = e.changedTouches[0].clientX
  }
}

function handleTouchEnd(e) {
  // only if 1 finger touched the device
  if (e.changedTouches?.length === 1 && startX.value) {
    // save the touch end position
    endX.value = e.changedTouches[0].clientX

    // check if touch is to left or right
    if (startX.value < endX.value) {
      handleTouchToRight()
    } else {
      handleTouchToLeft()
    }

    endX.value = null
    startX.value = null
  }
}

async function handleTouchToRight() {
  // Close if
  // touch distance is longer enough
  if (endX.value - startX.value >= swipeDistance.value) {
    swipeDirection.value = 'right'
    await nextTick()
    emits('closed')
  }
}

async function handleTouchToLeft() {
  // Close if
  // touch distance is longer enough
  if (startX.value - endX.value >= swipeDistance.value) {
    swipeDirection.value = 'left'
    await nextTick()
    emits('closed')
  }
}
</script>
