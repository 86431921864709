<template>
  <!-- Notifications page link -->
  <router-link
    v-if="displayBell"
    v-slot="{ navigate }"
    custom
    :to="{ name: 'index-notifications' }"
  >
    <div
      class="relative inline cursor-pointer"
      @click="() => handleBellClick(navigate)"
    >
      <!-- Bell icon -->
      <app-icon
        library="coolicon"
        name="notification_outline"
        class="text-theme-500"
        size="text-3xl"
      />

      <!-- bg-body is applied to make the red dot border look transparent and overlap the notification icon -->
      <app-icon
        v-if="hasUnreadNotifications"
        library="coolicon"
        name="dot_05_xl"
        size="text-base"
        class="text-red-500 absolute right-0 bg-body rounded-1/2"
      />
    </div>
  </router-link>

  <!-- Notification mini preview -->
  <transition
    appear
    name="notification"
  >
    <app-mini-notification
      v-if="miniNotification"
      v-bind="miniNotification"
      class="mx-auto fixed max-w-screen-md w-fit px-4 top-12 inset-x-0 z-60"
      @closed="handleMiniNotificationClick"
    />
  </transition>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import { markNotificationsAsRead } from '@shared/http/api'
import AppMiniNotification from '@shared/components/features/notifications/AppMiniNotification.vue'
import AppIcon from '@shared/components/ui/AppIcon.vue'

defineProps({
  // Display bell or not
  displayBell: {
    type: Boolean,
    default: true,
  },
})

const store = useStore()
const route = useRoute()
const user = computed(() => store.state.auth.user)

const unreadNotificationsCount = computed(() => (
  user.value.attributes.unread_notifications_count
))

const hasUnreadNotifications = computed(() => unreadNotificationsCount.value > 0)

function handleBellClick(callback) {
  callback()
  window.scroll({ top: 0, left: 0, behavior: 'smooth' })
}

const miniNotification = ref(null)

function removeMini() {
  miniNotification.value = null
}

function markMiniNotificationAsRead() {
  if (miniNotification.value?.id) {
    markNotificationsAsRead({ ids: [miniNotification.value.id] })

    // Adapt unread notifications counter
    store.commit('auth/ADAPT_UNREAD_NOTIFICATIONS_COUNTER', -1)
  }
}

function handleMiniNotificationClick() {
  markMiniNotificationAsRead()
  removeMini()
}

function increaseCounter() {
  store.commit('auth/ADAPT_UNREAD_NOTIFICATIONS_COUNTER', 1)
}

function setMiniNotification(notification) {
  if (!miniNotification.value) {
    miniNotification.value = {
      id: notification.id,
      title: notification.title,
      source: {
        id: notification.source_id,
        type: notification.source_type,
      },
      type: notification.type,
    }

    setTimeout(() => {
      removeMini()
    }, 4000)
  }
}

function handleNotification(notification) {
  if (
    store.state.appName === 'app'
    && notification.type === 'App\\Notifications\\TravellerFolder\\CreatedNotification'
    && route.name === 'home'
  ) {
    // If a new traveller folder is created, refresh it
    // in order to display this new one on app home page
    store.dispatch('folders/getActiveFolder')
  }
}

// Listen to new notifications
window
  .Echo
  .private(`App.Models.User.${user.value.id}`)
  .notification((notification) => {
    increaseCounter()
    setMiniNotification(notification)
    handleNotification(notification)
  })
</script>
