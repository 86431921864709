export default {
  'key': 'c_gmaps',
  'type': 'api',
  'name': 'Google Maps',
  'uri': 'https://policies.google.com/privacy',
  'needConsent': true,
  'cookies': [],
  'js': () => {},
  'fallback': () => {},
}
