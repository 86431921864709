import {
  fetchLastPrivacyPolicy,
  fetchLastTermsCondition,
} from '@shared/http/api'

export default {
  namespaced: true,

  state: {
    privacyPolicy: null,
    termsCondition: null,
  },

  getters: {
  },

  mutations: {
    SET_PRIVACY_POLICY(state, value) {
      state.privacyPolicy = value
    },

    SET_TERMS_CONDITION(state, value) {
      state.termsCondition = value
    },
  },

  actions: {
    async getLastTerms({ commit }) {
      let privacyPolicy = null
      let termsCondition = null

      await fetchLastPrivacyPolicy()
        .then(({ data }) => {
          if (data?.data?.id) privacyPolicy = data.data
        })

      await fetchLastTermsCondition()
        .then(({ data }) => {
          if (data?.data?.id) termsCondition = data.data
        })

      commit('SET_PRIVACY_POLICY', privacyPolicy)
      commit('SET_TERMS_CONDITION', termsCondition)
    },
  },
}
