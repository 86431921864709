import { defineRule, configure } from 'vee-validate'
import {
  email,
  mimes,
  required,
  size,
  confirmed,
  min_value,
  max_value,
} from '@vee-validate/rules'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import i18n from '@shared/i18n/vue-i18n'
import { translateAttributeName, translateAttributeErrorLabel } from '@shared/helpers/attributes'
import { formatDateShort } from '@shared/helpers/datetime'

const phone = (value) => {
  if (value) {
    const phoneNumber = parsePhoneNumberFromString(value.toString())
    return phoneNumber?.isValid()
  }

  return true
}

configure({
  generateMessage: ({ field, rule }) => {
    rule.attribute = translateAttributeErrorLabel(field)
    return i18n.global.t(`validation.${rule.name}`, { ...rule })
  },
})

defineRule('email', email)
defineRule('mimes', mimes)
defineRule('required', required)
defineRule('phone', phone)
defineRule('confirmed', confirmed)

defineRule('min.numeric', (value, params, obj) => {
  if (min_value(value, params)) {
    return true
  }

  return i18n.global.t('validation.min.numeric', {
    attribute: translateAttributeName(obj.field),
    min: params[0],
  })
})

defineRule('max.numeric', (value, params, obj) => {
  if (max_value(value, params)) {
    return true
  }

  return i18n.global.t('validation.max.numeric', {
    attribute: translateAttributeName(obj.field),
    max: params[0],
  })
})

defineRule('size.file', (value, params, obj) => {
  if (size(value, params)) {
    return true
  }

  return i18n.global.t('validation.size.file', {
    attribute: translateAttributeName(obj.field),
    size: params[0],
  })
})

defineRule('dimensions', async (value, params, obj) => {
  if (value) {
    return new Promise((resolve) => {
      const constraints = params.map((param) => param.split('='))

      // Build image from value, then validate its dimensions
      const image = new Image()
      image.src = URL.createObjectURL(value)

      image.onerror = () => resolve(false)
      image.onload = () => {
        // Check each constraint
        constraints.forEach((constraint) => {
          const constraintName = constraint[0]
          const constraintValue = constraint[1]

          // Compare image's dimensions with provided constraint
          switch (constraintName) {
            case 'max_width':
              if (image.width > constraintValue) {
                resolve(i18n.global.t('validation.max.width', {
                  attribute: translateAttributeName(obj.field),
                  max: constraintValue,
                }))
              }
              break
            case 'max_height':
              if (image.height > constraintValue) {
                resolve(i18n.global.t('validation.max.height', {
                  attribute: translateAttributeName(obj.field),
                  max: constraintValue,
                }))
              }
          }
        })
      }
    })
  }

  return true
})

defineRule('credential', (value) => {
  const credentialI18n = i18n.global.t('validation.attributes.credential')

  // Validate as an email
  if (email(value)) {
    return true
  }

  return i18n.global.t('validation.email', { attribute: credentialI18n })
})

defineRule('max.date', (value, params, obj) => {
  if (!value || !params[0] || value <= params[0]) {
    return true
  }

  return i18n.global.t('validation.max.numeric', {
    attribute: translateAttributeName(obj.field),
    max: formatDateShort(params[0]),
  })
})

defineRule('min.date', (value, params, obj) => {
  if (!value || !params[0] || value >= params[0]) {
    return true
  }

  return i18n.global.t('validation.min.numeric', {
    attribute: translateAttributeName(obj.field),
    min: formatDateShort(params[0]),
  })
})
