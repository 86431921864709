const state = {
  mainKey: 0,
  afterLoginRedirectRoute: null,
  router: null,
}

const mutations = {
  SET_ROUTER(state, value) {
    state.router = value
  },
  REFRESH_MAIN_KEY(state) {
    state.mainKey += 1
  },
  SET_AFTER_LOGIN_REDIRECT_ROUTE(state, value) {
    state.afterLoginRedirectRoute = value
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
