const state = {
  active: false,
  text: null,
}

const mutations = {
  ENABLE(state) {
    state.active = true
  },
  DISABLE(state) {
    state.active = false
  },
  SET_TEXT(state, text) {
    state.text = text
  },
  RESET(state) {
    state.active = false
    state.text = null
  },
}

const actions = {
}

const getters = {
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
