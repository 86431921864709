import {
  faAddressBook,
  faAddressCard,
  faArrowRight,
  faArchive,
  faBan,
  faBars,
  faBed,
  faBell,
  faBicycle,
  faCar,
  faCircle,
  faCircleNotch,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClock,
  faCookie,
  faCrosshairs,
  faDoorOpen,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAudio,
  faFolder,
  faGem,
  faGlobe,
  faHandsHelping,
  faHashtag,
  faHeart,
  faHome,
  faHourglassEnd,
  faInfoCircle,
  faKey,
  faLanguage,
  faList,
  faLocationDot,
  faLocationArrow,
  faLock,
  faMap,
  faMapLocationDot,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faMapSigns,
  faMicrophone,
  faMinus,
  faMoneyBill,
  faPaperclip,
  faPause,
  faPen,
  faPersonWalking,
  faPhone,
  faPlay,
  faPlus,
  faPowerOff,
  faQrcode,
  faQuestionCircle,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faSignInAlt,
  faSignOutAlt,
  faSlash,
  faSort,
  faSquare,
  faStar,
  faSuitcase,
  faSyncAlt,
  faTag,
  faTaxi,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUpload,
  faUser,
  faUserCircle,
  faUserClock,
  faUserPlus,
  faUserTie,
  faUsers,
  faVolumeMute,
  faVolumeUp,
  faWifi,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faFacebookF,
  faGoogle,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons'

import {
  faCircle as regularFaCircle,
  faCopyright,
  faFilePdf,
  faHandshake,
  faLifeRing,
  faRectangleList,
  faTrashAlt,
  faStar as regularFaStar,
} from '@fortawesome/free-regular-svg-icons'

library.add(
  faAddressBook,
  faAddressCard,
  faArrowRight,
  faArchive,
  faBan,
  faBars,
  faBed,
  faBell,
  faBicycle,
  faCar,
  faCircle,
  regularFaCircle,
  faCircleNotch,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClock,
  faCookie,
  faCopyright,
  faCrosshairs,
  faDoorOpen,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFacebookF,
  faFileAudio,
  faFilePdf,
  faFolder,
  faGem,
  faGlobe,
  faGoogle,
  faHandshake,
  faHandsHelping,
  faHashtag,
  faHeart,
  faHome,
  faHourglassEnd,
  faInfoCircle,
  faKey,
  faLanguage,
  faLifeRing,
  faList,
  faLocationDot,
  faLocationArrow,
  faLock,
  faMap,
  faMapLocationDot,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faMapSigns,
  faMicrophone,
  faMinus,
  faMoneyBill,
  faPaperclip,
  faPause,
  faPen,
  faPersonWalking,
  faPhone,
  faPlay,
  faPlus,
  faPowerOff,
  faQrcode,
  faQuestionCircle,
  faRectangleList,
  faSearch,
  faShareAlt,
  faShieldAlt,
  faSignInAlt,
  faSignOutAlt,
  faSlash,
  faSort,
  faSquare,
  faStar,
  faSuitcase,
  faSyncAlt,
  faTag,
  faTaxi,
  faTimes,
  faTimesCircle,
  faTrash,
  faTrashAlt,
  faTwitter,
  faUndo,
  faUserCircle,
  faUserClock,
  faUpload,
  faUser,
  faUserPlus,
  faUserTie,
  faUsers,
  faVolumeMute,
  faVolumeUp,
  faWifi,
  faXmarkCircle,
  regularFaStar,
)
