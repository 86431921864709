import { watch } from 'vue'
import { useStore } from 'vuex'
import { Interval, DateTime } from 'luxon'
import { isNil } from 'lodash'

import { fetchCurrentUser } from '@shared/http/api'

export default function useSessionExpiration() {
  const store = useStore()

  watch(() => store.state.auth.sessionExpiredAt, (expiredAt) => {
    // Authenticated client-side
    if (expiredAt && !store.state.auth.reauthenticationNeeded) {
      // Get in how many ms the session will expire,
      // from expiredAt datetime
      const expirationInMs = Interval
        .fromDateTimes(
          DateTime.now(),
          expiredAt,
        )
        .length('milliseconds')

      if (store.state.auth.sessionExpirationTimer) {
        store.commit('auth/CLEAR_SESSION_EXPIRATION_TIMER')
      }

      store.commit(
        'auth/SET_SESSION_EXPIRATION_TIMER',
        setTimeout(async () => {
          // Check if really not authenticated server-side
          fetchCurrentUser()
            .then(({ data }) => {
              const serverAuthenticated = !isNil(data?.data?.id)
              if (!serverAuthenticated) {
                // Display re-authentication modal
                store.commit('auth/SET_REAUTHENTICATION_NEEDED', true)
              }
            })
        }, expirationInMs),
      )
    }
  })
}
