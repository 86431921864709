import Echo from 'laravel-echo'
import * as api from '@shared/http/api'
import Pusher from 'pusher-js'

window.Pusher = Pusher

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: true,
  authorizer: (channel) => (
    {
      authorize: (socketId, callback) => {
        api.broadcastingAuth({
          socket_id: socketId,
          channel_name: channel.name,
        })
          .then((response) => {
            callback(false, response.data)
          })
          .catch((error) => {
            callback(true, error)
          })
      },
    }
  ),
})
