<template>
  <teleport
    v-if="isMounted"
    :to="to"
    :disabled="disabled"
  >
    <slot />
  </teleport>
</template>

<script setup>
import { onMounted, ref } from 'vue'

defineProps({
  // Specify target container.
  // Can either be a selector or an actual element.
  to: {
    type: [String, HTMLElement],
    required: true,
  },
  // When `true`, the content will remain in its original
  // location instead of moved into the target container.
  // Can be changed dynamically.
  disabled: {
    type: Boolean,
    default: false,
  },
})

// Wait for this component to be mounted before using teleport component
// It avoids error when the "to" props' target isn't available yet in the DOM
const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})
</script>
